import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { PageLayout } from 'layouts/PageLayout'
import { Headline } from 'styles/typography'


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <PageLayout>
      <Helmet title={frontmatter.title} />

      <Headline>
        {frontmatter.title}
      </Headline>

      <div
        className="legal-content"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
